// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-campaigns-index-js": () => import("./../src/templates/campaigns/index.js" /* webpackChunkName: "component---src-templates-campaigns-index-js" */),
  "component---src-templates-links-index-js": () => import("./../src/templates/links/index.js" /* webpackChunkName: "component---src-templates-links-index-js" */)
}

